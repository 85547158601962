/**
 * @购物车相关接口
 * 2022-08-03 kadiam
*/
import bossXypq from '../utils/bossXypq'
import bossCloud from '../utils/bossCloud'

// 用户购物车商品种类
// export function shoppingCart_productTypes(data) {
//   return bossXypq({ url: 'hx.baoxian/shoppingCart/productTypes', method: 'get' })
// }

// 加入购物车
// export function shoppingCart_addUnion(data) {
//   return bossXypq({ url: 'hx.baoxian/shoppingCart/addUnion', method: 'post', data })
// }

// 购物车列表
// export function shoppingCart_listUnion() {
//   return bossXypq({ url: 'hx.baoxian/shoppingCart/listUnionV2', method: 'get' })
// }

// 修改购物车商品数量
// export function shoppingCart_update(data) {
//   return bossXypq({ url: 'hx.baoxian/shoppingCart/updateV2', method: 'post', data })
// }

// 购物车商品状态更改
export function shoppingCart_check(data) {
  return bossXypq({ url: 'hx.baoxian/shoppingCart/checkV2', method: 'post', data })
}

// 购物车商品删除
export function shoppingCart_delete(data) {
  return bossXypq({ url: 'hx.baoxian/shoppingCart/deleteV2?shoppingCartId=' + data, method: 'get' })
}

// 购物车商品删除 批量
export function deleteBatch(data) {
  return bossXypq({ url: 'hx.baoxian/shoppingCart/deleteBatch', method: 'post', data })
}

// 购物车选中商品总价
export function shoppingCart_totalPrice(data) {
  return bossXypq({ url: 'hx.baoxian/shoppingCart/totalPrice', method: 'get' })
}
// 购物车 全选/全不选
export function shoppingCart_checkV2All(data) {
  return bossXypq({ url: 'hx.baoxian/shoppingCart/checkV2All', method: 'post', data })
}
/**
 * @购物车相关接口 2.0 王涛
 * 2024-11-13 kadiam
*/
// 用户购物车商品种类
export function shoppingCart_productTypes(data) {
  return bossCloud({ url: 'mall/api/shoppingCart/productTypes', method: 'get' })
}
// 购物车列表
export function shoppingCart_listUnion() {
  return bossCloud({ url: 'mall/api/shoppingCart/list', method: 'get' })
}
// 修改购物车商品数量
export function shoppingCart_update(data) {
  return bossCloud({ url: 'mall/api/shoppingCart/update', method: 'post', data })
}
// 加入购物车
export function shoppingCart_addUnion(data) {
  return bossCloud({ url: 'mall/api/shoppingCart/add', method: 'post', data })
}
// spu商品列表
export function ProductListspu(data) {
  return bossCloud({ url: 'mall/api/mallProduct/spuProductList?productId=' + data, method: 'get'})
}
